@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@300&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "TH K2D July8";
  src: local("TH K2D July8"),
    url("/src/pages/job/etc/TH\ K2D\ July8.ttf") format("truetype");
}

@font-face {
  font-family: "THSarabun";
  src: local("THSarabun"),
    url("/src/pages/job/etc/THSarabun.ttf") format("truetype");
}

@font-face {
  font-family: "TH Baijam";
  src: local("THSarabun"),
    url("/src/pages/job/etc/TH\ Baijam.ttf") format("truetype");
}

@font-face {
  font-family: "TH Niramit AS";
  src: local("TH Niramit AS"),
    url("/src/pages/job/etc/TH\ Niramit\ AS.ttf") format("truetype");
}

html {
  font-family: "Noto Sans Thai", sans-serif;
  font-family: "Inter", sans-serif;
  font-family: "Kanit", sans-serif;
  font-family: "Sarabun", sans-serif;
  font-family: "THSarabun", sans-serif;
  font-family: "TH K2D July8", sans-serif;
  font-family: "TH Baijam", sans-serif;
  font-family: "TH Niramit AS", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Kanit",
    "Sarabun", "THSarabun", sans-serif, "TH K2D July8", "TH Baijam", "TH Niramit AS";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   
} 


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
    
}
